// src/TestPage.js

import React from 'react';

function TestPage() {
    return (
        <div>
            <h1>Test Page</h1>
            <p>로그인 성공</p>
        </div>
    );
}

export default TestPage;
